<template lang="pug">
  login-forgot-password(scope='platform')
</template>

<script>
export default {
  name: 'LoginPasswordForgot',

  mounted() {
    this.$auth.expire()
  },
}
</script>
